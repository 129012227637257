import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend as Backend } from 'react-dnd-html5-backend';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider as NextAuthProvider } from 'next-auth/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';

function Application({ Component, pageProps }) {
	const [queryClient] = useState(() => new QueryClient());

	return (
		<DndProvider backend={Backend}>
			<ChakraProvider>
				<NextAuthProvider>
					<QueryClientProvider client={queryClient}>
						<Hydrate state={pageProps.dehydratedState}>
							<Component {...pageProps} />
						</Hydrate>
					</QueryClientProvider>
				</NextAuthProvider>
			</ChakraProvider>
		</DndProvider>
	);
}

export default Application;
